//Overwrite global CSS
@import "./style-functions.scss";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

header nav .nav-logotype img {
  margin-top: -40px !important;

  @include custom(1025){
    margin-top: -25px !important;
  }
  @include sm {
    margin-top: 10px !important;
  }
}
footer .footer-copy img {
  margin-top: -10px !important;

  @include md {
    margin-top: 5px !important;
  }
}
.contact .cover img {
  height: inherit !important;
  border-radius: 50px !important;

  @include custom(1366) {
    border-radius: 0 !important;
  }

  @include sm {
    margin-top: 30px !important;
    height: 250px !important;
  }
}
.products-cover .cover-overlay {
  background: linear-gradient(90deg, rgba(104, 43, 124, 0.5) 0%, rgba(255, 255, 255, 0) 100%) !important;
}
